import React from 'react';
import SectionTitle from '../../subcomponents/sectiontitle/this';
import InfoRow from '../../subcomponents/sectionRow.jsx/this';
import CrosshairCanvas from '../../subcomponents/crosshaircanvas/this';
import './this.scss'

export default function Socials({ csgoObj, createToast }) {
    const {mouse, crosshair, video} = csgoObj

    function copyCrosshair() {
        navigator.clipboard.writeText(`apply_crosshair_code ${crosshair?.code}`);
        createToast('Copied crosshair code.');
    };

    function crosshairMouseMove (event) {
        let canvasSize = document.getElementById('csgo-crosshair-canvas').offsetHeight; document.getElementById('csgo-crosshair-canvas').style.position = 'absolute';
        document.getElementById('csgo-crosshair-canvas').style.left = `${event.pageX + 5}px`;
        document.getElementById('csgo-crosshair-canvas').style.top = `${event.pageY + 5}px`;
        document.getElementById('csgo-crosshair-canvas').style.transform = `translate(-${Math.floor(canvasSize)}px, -${Math.floor(canvasSize)}px)`
    }

    function crosshairMouseLeave () {
        document.getElementById('csgo-crosshair-canvas').style.position = '';
        document.getElementById('csgo-crosshair-canvas').style.left = ``;
        document.getElementById('csgo-crosshair-canvas').style.top = ``;
        document.getElementById('csgo-crosshair-canvas').style.transform = ``
    }

    return <section id="csgo">
        <SectionTitle imgSrc="https://static.046.ooo/links/sections/csgo.svg" title='CS:GO Settings' />
        {
        mouse ?
            <section id="csgo-mouse">
                <h1>Mouse</h1>
                <InfoRow rowName='DPI' rowValue={mouse.dpi} />
                <InfoRow rowName='Main Sensitivity' rowValue={mouse.mainSensitivity} />
                <InfoRow rowName='Zoom Sensitivity' rowValue={mouse.zoomSensitivity} />
                <InfoRow rowName='eDPI' rowValue={mouse.eDpi} />
            </section>
            :
            ""
        }
        {
        crosshair?
            <section id="csgo-crosshair" onMouseMove={crosshairMouseMove} onMouseLeave={crosshairMouseLeave} >
                <div className='crosshairHeader'>
                    <h1>Crosshair</h1>
                        <CrosshairCanvas crosshair={crosshair.code} />
                    <img className='copyCrosshair' src='https://static.046.ooo/links/icons/copy.svg' alt='copy' onClick={copyCrosshair} />
                </div>
                <InfoRow rowName='Code'                rowValue={crosshair.code} />
                <InfoRow rowName='Style'               rowValue={crosshair.style} />
                <InfoRow rowName='T-Style'             rowValue={crosshair.tStyleEnabled} />
                <InfoRow rowName='Center Dot'          rowValue={crosshair.centerDotEnabled} />
                <InfoRow rowName='Length'              rowValue={crosshair.length} />
                <InfoRow rowName='Thickness'           rowValue={crosshair.thickness} />
                <InfoRow rowName='Gap'                 rowValue={crosshair.gap} />
                <InfoRow rowName='Fixed Crosshair Gap' rowValue={crosshair.fixedCrosshairGap} />
                
                {
                    crosshair.outlineEnabled ? 
                    <InfoRow rowName='Outline Thickness' rowValue={crosshair.outline} />
                    :
                    <InfoRow rowName='Outline'           rowValue='Disabled' />
                }
        
                {
                    crosshair.color === 'Custom' ?
                    <>
                    <InfoRow rowName='Color'             rowValue={crosshair.color} />
                    <InfoRow rowName='Red Value'         rowValue={crosshair.red} />
                    <InfoRow rowName='Green Value'       rowValue={crosshair.green} />
                    <InfoRow rowName='Blue Value'        rowValue={crosshair.blue} />
                    </>
                    :
                    <InfoRow rowName='Color'             rowValue={crosshair.color} />
                }
        
                {
                    crosshair.alphaEnabled ? 
                    <InfoRow rowName='Alpha Value'       rowValue={crosshair.alpha} />
                    :
                    <InfoRow rowName='Alpha'             rowValue='Disabled' />
                }
        
                <InfoRow rowName='Split Distance'          rowValue={crosshair.splitDistance} />
                <InfoRow rowName='Inner Split Alpha Value' rowValue={crosshair.innerSplitAlpha} />
                <InfoRow rowName='Outer Split Alpha Value' rowValue={crosshair.outerSplitAlpha} />
        
                <InfoRow rowName='Split Size Ratio'        rowValue={crosshair.splitSizeRatio} />
        
                <InfoRow rowName='Deployed Weapon Gap'     rowValue={crosshair.deployedWeaponGapEnabled} />
            </section>
            :
            ""
        }
        {
        video?
            <section id="csgo-video">
                <h1>Video</h1>
                <InfoRow rowName='Resolution'            rowValue={video.resolution} />
                <InfoRow rowName='Aspect Ratio'          rowValue={video.aspectRatio} />
                <InfoRow rowName='Display Mode'          rowValue={video.displayMode} />
                <InfoRow rowName='VSync'                 rowValue={video.vsync} />
                <InfoRow rowName='Global Shadow Quality' rowValue={video.globalShadowQuality} />
                <InfoRow rowName='Model/Texture Detail'  rowValue={video.modelTextureDetail} />
                <InfoRow rowName='Effect Detail'         rowValue={video.effectDetail} />
                <InfoRow rowName='Shader Detail'         rowValue={video.shaderDetail} />
                <InfoRow rowName='MSAA'                  rowValue={video.msaa} />
                <InfoRow rowName='FXAA'                  rowValue={video.fxaa} />
                <InfoRow rowName='Texture Filtering'     rowValue={video.textureFiltering} />
            </section>
            :
            ""
    }
    </section>
}
