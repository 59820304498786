import React from 'react';
import Team from '../../subcomponents/team/this'
import './this.scss'

export default function Header({ userData, createToast }) {
    let contactAnimationStatus = 0
    function copyContact() {
        let contact = userData.contact
        let svg = document.querySelector('svg.contact')

        new RegExp(`^@.{3,32}#[0-9]{4}$`).test(contact) ?
            navigator.clipboard.writeText(contact.substring(1))
            :
            navigator.clipboard.writeText(contact)


        if (contactAnimationStatus === 0) {
            contactAnimationStatus = 1
            let ContactClipFrame = 0
            let ContactClipSubFrame = 0
            new RegExp(`^@.{3,32}#[0-9]{4}$`).test(contact) ?
                createToast('Copied Discord.')
                :
                createToast('Copied email.')
            const clipboardAnimation = () => {
                if (ContactClipFrame <= 9) {
                    ContactClipSubFrame++
                    svg.setAttribute('viewBox', `0 ${-15.515 + 0.218267 + 0.181638 * Math.exp(0.700293 * ContactClipSubFrame)} 128 128`)
                }
                if (ContactClipFrame === 20) { ContactClipSubFrame = 10 }
                if (ContactClipFrame >= 160) {
                    ContactClipSubFrame--
                    svg.setAttribute('viewBox', `0 ${-15.515 + 0.218267 + 0.181638 * Math.exp(0.700293 * ContactClipSubFrame)} 128 128`)
                }
                ContactClipFrame++
                if (ContactClipFrame >= 170) { clearInterval(animationInterval); ContactClipFrame = 0; ContactClipSubFrame = 0; contactAnimationStatus = 0 }
            }
            let animationInterval = setInterval(clipboardAnimation, 10)
        }
    }


    return <header>
        <img src={userData.images.pfpUrl} alt='' className="unselectable" />
        <h1>{userData.name}</h1>
        {
            userData.teams?
            <div className="unselectable teams">
            {
                userData.teams.map((team, i) => <Team team={team} vr={Boolean(i !== (userData?.teams?.length || 0) - 1)} key={`team-${team.name}`} />) 
            }
            </div>
            :
            <></>
        }
        {
            userData.contact ?
            <div className='unselectable center' style={{height: '35px'}}>
                <div className='pointer grow header' onClick={copyContact}>
                    {
                        /^@.{3,32}#[0-9]{4}$/.test("userData.contact") ?
                        <svg className="contact" style={{maxHeight: '13px', maxWidth: '13px'}} width="128" height="128" viewBox="0 -15.515 128 128" xmlns="http://www.w3.org/2000/svg"><path d="M51.2 184.48a19.206 19.206 0 0 0-18.112 12.8H25.6a19.2 19.2 0 0 0-19.2 19.2v76.8a19.2 19.2 0 0 0 19.2 19.2h76.8a19.2 19.2 0 0 0 19.2-19.2v-76.8a19.2 19.2 0 0 0-19.2-19.2h-7.488a19.206 19.206 0 0 0-18.112-12.8zm-6.4 19.2a6.4 6.4 0 0 1 6.4-6.4h25.6a6.4 6.4 0 1 1 0 12.8H51.2a6.4 6.4 0 0 1-6.4-6.4zm43.392 42.4a6.4 6.4 0 1 0-9.984-8l-21.139 26.419-7.744-7.744a6.4 6.4 0 0 0-9.05 9.05l12.8 12.8a6.4 6.4 0 0 0 9.524-.525l25.6-32z" clipRule="evenodd" fill="#0f5" fillRule="evenodd"/><g data-name="图层 2"><g data-name="Discord Logos"><path className="cls-1" d="M108.432 8.106A105.865 105.865 0 0 0 82.024-.02a72.55 72.55 0 0 0-3.383 6.876 98.344 98.344 0 0 0-29.308 0A72.862 72.862 0 0 0 45.95-.019a106.61 106.61 0 0 0-26.428 8.145C2.809 32.853-1.722 56.966.544 80.736a106.449 106.449 0 0 0 32.388 16.26 78.228 78.228 0 0 0 6.937-11.185 68.885 68.885 0 0 1-10.923-5.216c.916-.664 1.812-1.349 2.678-2.013a76.084 76.084 0 0 0 64.757 0c.876.715 1.772 1.4 2.678 2.013a69.147 69.147 0 0 1-10.944 5.226 77.524 77.524 0 0 0 6.937 11.175 105.966 105.966 0 0 0 32.409-16.25c2.658-27.566-4.54-51.457-19.029-72.64zM42.74 66.118c-6.313 0-11.528-5.729-11.528-12.777s5.034-12.826 11.508-12.826 11.648 5.779 11.537 12.826-5.084 12.777-11.517 12.777zm42.527 0c-6.323 0-11.518-5.729-11.518-12.777s5.034-12.826 11.518-12.826 11.618 5.779 11.508 12.826-5.075 12.777-11.508 12.777z" fill="#fff" data-name="Discord Logo - White"/></g></g></svg>
                        :
                        <svg className='contact' style={{maxHeight: '13px', maxWidth: '13px'}} width='128' height='128' viewBox='0 -15.515 128 128' xmlns='http://www.w3.org/2000/svg'><g fill='#fff'><path d='M60.56 53.179A5.811 5.811 0 0 0 64 54.305a5.81 5.81 0 0 0 3.44-1.126L128 8.77v82.386a5.819 5.819 0 0 1-5.817 5.818H5.819A5.818 5.818 0 0 1 0 91.156V8.77l11.637 8.534z' /><path d='M64 41.27 7.722 0h112.555z' /></g><path d='M51.2 184.48a19.206 19.206 0 0 0-18.112 12.8H25.6a19.2 19.2 0 0 0-19.2 19.2v76.8a19.2 19.2 0 0 0 19.2 19.2h76.8a19.2 19.2 0 0 0 19.2-19.2v-76.8a19.2 19.2 0 0 0-19.2-19.2h-7.488a19.206 19.206 0 0 0-18.112-12.8zm-6.4 19.2a6.4 6.4 0 0 1 6.4-6.4h25.6a6.4 6.4 0 1 1 0 12.8H51.2a6.4 6.4 0 0 1-6.4-6.4zm43.392 42.4a6.4 6.4 0 1 0-9.984-8l-21.139 26.419-7.744-7.744a6.4 6.4 0 0 0-9.05 9.05l12.8 12.8a6.4 6.4 0 0 0 9.524-.525l25.6-32z' clipRule='evenodd' fill='#00FF55' fillRule='evenodd' /></svg>
                    }
                    <p className='contact'>{userData.contact}</p>
                </div>
            </div>
            :
            ""
        }

    </header>
}
