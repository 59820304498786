import React from 'react';
import './this.scss'

export default function SocialsButton({ url }) {
    let logoBg
    let logoImg
    let title

    if (url.includes('youtube.com')) {
        logoBg = `#F00`
        logoImg = 'https://static.046.ooo/links/logos/youtube.svg'
        title = 'YouTube'
    }
    if (url.includes('twitch.tv')) {
        logoBg = `#9146FF`
        logoImg = 'https://static.046.ooo/links/logos/twitch.svg'
        title = 'Twitch'
    }
    if (url.includes('instagram.com')) {
        logoBg = `radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)`
        logoImg = 'https://static.046.ooo/links/logos/instagram.svg'
        title = `Instagram`
    }
    if (url.includes('twitter.com')) {
        logoBg = `#0e1419`
        logoImg = 'https://imagedelivery.net/BSi4WAjIsB6KdSVrM51mGw/eea541e2-d618-4b3b-9e54-207ab7482f00/height=128'
        title = `X`
    }
    if (url.includes('github.com')) {
        logoBg = `#171515`
        logoImg = 'https://static.046.ooo/links/logos/github.svg'
        title = `GitHub`
    }
    if (url.includes('spotify.com')) {
        logoBg = `#1db954`
        logoImg = 'https://static.046.ooo/links/logos/spotify.svg'
        title = `Spotify`
    }
    if (url.includes('steamcommunity.com')) {
        logoBg = `#231f20`
        logoImg = 'https://static.046.ooo/links/logos/steam.svg'
        title = `Steam`
    }
    if (url.includes('esea.net')) {
        logoBg = `#111211`
        logoImg = 'https://static.046.ooo/links/logos/esea.svg'
        title = `ESEA`
    }
    if (url.includes('faceit.com')) {
        logoBg = `#141616`
        logoImg = 'https://static.046.ooo/links/logos/faceit.svg'
        title = `FACEIT`
    }
    
    return <a className='unselectable grow socialsButton' href={url} target='_blank' rel="noreferrer">
        <div className='socialsImg' style={{ background: logoBg }}>
            <img src={logoImg} alt='' loading='lazy' />
        </div>
        <p>{title}</p>
        <svg width="27.216" height="45" viewBox="0 0 0.816 1.35" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M16.157-4.843v24h-24v-24zM4.75 18.415l-.012.002-.07.035-.02.004-.015-.004-.07-.035c-.01-.003-.02 0-.024.005l-.004.011-.018.427.005.02.011.013.104.074.015.004.012-.004.103-.074.013-.016.003-.017-.017-.427c-.002-.01-.008-.017-.016-.018zm.265-.113-.014.002-.185.093-.01.01-.002.011.018.43.004.013.009.007.2.092c.013.004.024 0 .03-.008l.003-.014-.034-.614a.026.026 0 0 0-.02-.022zm-.715.002a.023.023 0 0 0-.028.006l-.006.014-.034.614c0 .012.007.021.017.024l.016-.002.2-.092.01-.008.004-.012.018-.43-.003-.012-.01-.01z" /><path d="M.775.575a.141.141 0 0 1 0 .2l-.533.534a.141.141 0 1 1-.2-.2L.475.675.042.242a.141.141 0 1 1 .2-.2z" fill="#fff" /></g></svg>
    </a>
}