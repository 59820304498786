import React from 'react';
import './this.scss'

export default function Team({ team, vr }) {
    return <>
        {
            typeof team === "string" ?
            <a className='team' href>
                <img className='unselectable' src={team.logo} alt='' />
                <p>{team}</p>
            </a>
            :
            <a className='team' href={team.siteUrl}>
                {
                    team.logos?.small? 
                    <img className='unselectable' src={team.logos.small} alt='' />
                    :
                    <></>
                }
                <p>{team.name}</p>
            </a>
        }
        {
            vr ?
            <div className="vr" />
            :
            <></>
        }
    </>
}
