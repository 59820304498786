import React from 'react';
import SectionTitle from '../../subcomponents/sectiontitle/this';
import ProductButton from '../../subcomponents/buttons/products';
import './this.scss'

export default function Products({ productsArray }) {
    return <section id="Products">
        {
            productsArray.map((section, i) => <section id={`products-${section.name.toLowerCase().replace(' ', '-')}`} key={`products-section-${i}`}>
                <SectionTitle imgSrc={section.iconURL} title={section.name} />
                <div className='productsContainer'>
                    {section.products.map((product) => <ProductButton product={product} key={`product-${i}-${product.type}`}/>)}
                </div>
            </section>)
        }
    </section>
}
