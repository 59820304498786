import React, { useEffect } from 'react';
import { decodeCrosshairShareCode } from 'csgo-sharecode';

export default function CrosshairCanvas({ crosshair }) {
    useEffect(() => {
        let cObj = decodeCrosshairShareCode(crosshair)
        let canvasElement = document.getElementById('csgo-crosshair-canvas')
        let cCtx = canvasElement.getContext('2d')
        let cCtxRed = 250
        let cCtxGreen = 46
        let cCtxBlue = 46
        let cCtxAlpha = 0.5
        let cCtxColor = ""
        let cCtxOutlineColor = ""
        let cCtxLength = 0
        let cCtxThickness = 0
        let cCtxGap = 0
        let cCtxOutline = 0
        let cCtxOutlineDecimal = 0
        let cCtxTotalSize = 0
        if (cObj.style >= 2) {
            if (cObj.color === 1) { cCtxRed = 46; cCtxGreen = 250; cCtxBlue = 46 }
            if (cObj.color === 2) { cCtxRed = 250; cCtxGreen = 250; cCtxBlue = 46 }
            if (cObj.color === 3) { cCtxRed = 46; cCtxGreen = 46; cCtxBlue = 250 }
            if (cObj.color === 4) { cCtxRed = 26; cCtxGreen = 250; cCtxBlue = 250 }
            if (cObj.color === 5) { cCtxRed = cObj.red; cCtxGreen = cObj.green; cCtxBlue = cObj.blue }
            if (cObj.alphaEnabled) { cCtxAlpha = cObj.alpha / 255 }
            cCtxColor = `rgba(${cCtxRed}, ${cCtxGreen}, ${cCtxBlue}, ${cCtxAlpha})`
            cCtxOutlineColor = `rgba(0,0,0,${cCtxAlpha})`
            if (cObj.length === 2) { cCtxLength = 4 }
            else if (cObj.length === 10) { cCtxLength = 22 }
            else { cCtxLength = Math.round(cObj.length * 2.25) }
            if (cObj.thickness < 0.66666665673255859999999) { cCtxThickness = 1 }
            else if (cObj.thickness === 2) { cCtxThickness = 4 }
            else { cCtxThickness = Math.round(cObj.thickness * 2.25) }
            if (cObj.outlineEnabled) {
                cCtxOutline = Math.floor(cObj.outline)
                if (cObj.outline.toString().includes(`.`)) { cCtxOutlineDecimal = 1 }
            }
            cCtxGap = Math.round(2 * (Math.floor(cObj.gap) + 5) + cCtxThickness - 2)
            cCtxTotalSize = cCtxLength * 2 + cCtxOutline * 2 + cCtxOutlineDecimal + cCtxGap
            canvasElement.setAttribute('height', cCtxTotalSize.toString())
            canvasElement.setAttribute('width', cCtxTotalSize.toString())
            canvasElement.style.height = `${cCtxTotalSize}px`
            canvasElement.style.width = `${cCtxTotalSize}px`

            if (!cObj.tStyleEnabled) {
                if (cObj.outlineEnabled) {
                    cCtx.fillStyle = cCtxOutlineColor
                    cCtx.fillRect(cCtxTotalSize / 2 - cCtxOutline - cCtxOutlineDecimal - cCtxThickness / 2, 0, cCtxOutline * 2 + cCtxOutlineDecimal + cCtxThickness, cCtxOutline * 2 + cCtxOutlineDecimal + cCtxLength)
                }
                cCtx.fillStyle = cCtxColor
                cCtx.fillRect(cCtxTotalSize / 2 - cCtxThickness / 2, cCtxOutline, cCtxThickness, cCtxLength)
            }

            if (cObj.outlineEnabled) {
                cCtx.fillStyle = cCtxOutlineColor
                cCtx.fillRect(0, cCtxTotalSize / 2 - cCtxOutline - cCtxOutlineDecimal - cCtxThickness / 2, cCtxOutline * 2 + cCtxOutlineDecimal + cCtxLength, cCtxOutline * 2 + cCtxOutlineDecimal + cCtxThickness)
            }
            cCtx.fillStyle = cCtxColor
            cCtx.fillRect(cCtxOutline, cCtxTotalSize / 2 - cCtxThickness / 2, cCtxLength, cCtxThickness)

            if (cObj.outlineEnabled) {
                cCtx.fillStyle = cCtxOutlineColor
                cCtx.fillRect(cCtxTotalSize / 2 + cCtxGap / 2 - cCtxOutline - cCtxOutlineDecimal, cCtxTotalSize / 2 - cCtxOutline - cCtxOutlineDecimal - cCtxThickness / 2, cCtxOutline * 2 + cCtxOutlineDecimal + cCtxLength, cCtxOutline * 2 + cCtxOutlineDecimal + cCtxThickness)
            }
            cCtx.fillStyle = cCtxColor
            cCtx.fillRect(cCtxTotalSize / 2 + cCtxGap / 2, cCtxTotalSize / 2 - cCtxThickness / 2, cCtxLength, cCtxThickness)

            if (cObj.outlineEnabled === true) {
                cCtx.fillStyle = cCtxOutlineColor
                cCtx.fillRect(cCtxTotalSize / 2 - cCtxOutline - cCtxOutlineDecimal - cCtxThickness / 2, cCtxTotalSize / 2 + cCtxGap / 2 - cCtxOutline - cCtxOutlineDecimal, cCtxOutline * 2 + cCtxOutlineDecimal + cCtxThickness, cCtxOutline * 2 + cCtxOutlineDecimal + cCtxLength)
            }
            cCtx.fillStyle = cCtxColor
            cCtx.fillRect(cCtxTotalSize / 2 - cCtxThickness / 2, cCtxTotalSize / 2 + cCtxGap / 2, cCtxThickness, cCtxLength)


            if (cObj.centerDotEnabled) {
                if (cObj.outlineEnabled) {
                    cCtx.fillStyle = cCtxOutlineColor
                    cCtx.fillRect(cCtxTotalSize / 2 - cCtxOutline - cCtxOutlineDecimal - cCtxThickness / 2, cCtxTotalSize / 2 - cCtxOutline - cCtxOutlineDecimal - cCtxThickness / 2, cCtxOutline * 2 + cCtxOutlineDecimal + cCtxThickness, cCtxOutline * 2 + cCtxOutlineDecimal + cCtxThickness)
                }
                cCtx.fillStyle = cCtxColor
                cCtx.fillRect(cCtxTotalSize / 2 - cCtxThickness / 2, cCtxTotalSize / 2 - cCtxThickness / 2, cCtxThickness, cCtxThickness)
            }
        }
    
    })
    return <canvas id="csgo-crosshair-canvas" className='unselectable' style={{ pointerEvents: 'none' }} />
}
