import React from 'react';
import SectionTitle from '../../subcomponents/sectiontitle/this';
import SocialsButton from '../../subcomponents/buttons/socials';
import './this.scss'

export default function Socials({ linksArray }) {
    return <section id="Links">
        <SectionTitle imgSrc='https://static.046.ooo/links/sections/links.svg' title='Links' />
        <div className='linksContainer'>
            {linksArray.map((link, i) => <SocialsButton url={link} key={`Socials-${link}`} />)}
        </div>
    </section>
}
