import React, { useState, useEffect } from 'react';
import Header from '../../components/header/this'
import Socials from '../../components/socials/this'
import CSGO from '../../components/csgo/this'
import Products from '../../components/products/this'
import Footer from '../../components/footer/this';
import './this.scss'

export default function App({user}) {
    const [userData, setUserData] = useState(null)
    async function createToast(toast) {
        document.querySelector('#toast-container').innerHTML = `<p class="toastfade">${toast}</p>`
    }

    useEffect(() => {
        fetch(`https://api.046.ooo/links/user/${user.toLowerCase()}`)
            .then(res => res.json())
            .then(data => setUserData(data))
            .catch(err => setUserData('failed'))
    }, [user])
    
    return <>
        <div className="unselectable" id="toast-container"/>
        <div className="main-container" style={{backgroundColor: "#0f00"}}>
        {
            userData? 
            <>
            {
                typeof userData === typeof {}? 
                <>
                <Header userData={userData} createToast={createToast} />
                <main>
                    {
                        userData.links? 
                            <Socials linksArray={userData.links} />
                            :
                            ""
                    }
                    {
                        userData.csgo ?
                            <CSGO csgoObj={userData.csgo} createToast={createToast} />
                            :
                            ""
                    }
                    {
                        userData.products ?
                            <Products productsArray={userData.products} />
                            :
                            ""
                    }
                </main>
                <Footer />
                </>
                :
                <h1 style={{textAlign: 'center'}}>Data could not be loaded<br/><span style={{fontSize: "1.5rem"}}>Try again later</span></h1>
            }
            </>
            :
            <div className="loader"></div>
        }
        </div>
    </>
}
