import React from 'react';
import './this.scss'

export default function SectionTitle({ imgSrc, title }) {
    let divClass = title.replaceAll(/[^\w\s]/gi, '').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1))
    divClass[0] = divClass[0].toLowerCase()
    divClass = divClass.toString().replaceAll(',', '')

    return <div className={`unselectable sectionTitle ${divClass}Header`}>
        <div className='sectionHr' />
        <img src={imgSrc} alt={''} loading='lazy' />
        <p>{title}</p>
        <div className='sectionHr' />
    </div>
}
